const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const cellphonePattern = /^\+?1?\d{9,15}$/

export const required = (value) => !!value || 'Este campo es necesario.'
export const requiredSelect = (value) => value?.length > 0 || "Este campo es necesario."
export const email = (value) => emailPattern.test(value) || value === '' || 'Ingrese un correo electrónico válido.'
export const cellphone = (value) => cellphonePattern.test(value) || value === '' || 'Ingrese un número del formato +51987654321.'
export const maxLength = (maxLength) => (value) => value?.length <= maxLength || `El campo no puede tener mas de ${maxLength} caracteres.`
export const minLength = (maxLength) => (value) => value?.length >= maxLength || `El campo debe tener mas de ${maxLength} caracteres.`
export const max = (maxValue) => (value) => value?.length >= maxValue || `El campo debe tener mas de ${maxValue} caracteres.`
export const min = (minValue) => (value) => value?.length >= minValue || `El campo debe tener mas de ${minValue} caracteres.`
export const percentage = (value) => value > 0 && value < 100 || `El campo debe ser un numero entre 0 y 100.`
export const equalTo = (valueName, valueToEqual) => (value) => value === valueToEqual || `El campo debe ser el mismo que ${valueName}.`
export const dniCarnet = (value) => (!!value && (value.toString()?.length === 8 || value.toString()?.length === 12 || value.toString()?.length === 20)) || 'El campo debe tener 8 digitos (DNI) o 12 (Carnet) o 20 (Pasaporte).'
export const phoneLength = (value) => (!!value && (value.toString()?.length >= 9 && value.toString()?.length <= 13)) || 'El campo debe tener entre 9 y 13 caracteres.'
export const maxValue = (maxValue) => (value) => value <= maxValue || `El campo no debe ser mayor a ${maxValue}.`
export const minValue = (minValue) => (value) => parseInt(value) >= parseInt(minValue) || `El campo no debe ser menor a ${minValue}.`
export const equalToLength = (equalToLength) => (value) => value?.length === equalToLength || `El campo debe tener ${equalToLength} caracteres.`
