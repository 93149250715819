<template>
  <UtilitiesObtainedGraph
    :showCourseFilter="true"
    :options="options"
    :series="series"
    width="450%"
    height="800"
  />
</template>

<script>
import UtilitiesObtainedGraph from '@/components/dashboardComponents/dashboard/UtilitiesObtainedGraph.vue'
import {settingsLinePlot} from '@/utils/graphs/ingresos'

export default {
  components: {
    UtilitiesObtainedGraph
  },
  data() {
    return {
      series: settingsLinePlot.series,
      options: settingsLinePlot.options,
    }
  }
}
</script>