<template>
  <v-form @submit.prevent="doSubmit" ref="form" v-model="validForm" lazy-validation>
    <v-row v-if="inscriptionEdited">
      <v-col cols="12" sm="6">
        <v-text-field
            outlined
            label="Nombres"
            append-icon="mdi-account"
            :rules="[rules.required]"
            v-model="inscriptionEdited.nombres"
        ></v-text-field>
        <v-text-field
            outlined
            label="Apellidos"
            append-icon="mdi-account"
            v-model="inscriptionEdited.apellidos"
            :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
            outlined
            label="Email"
            append-icon="mdi-account"
            @input="(val) => (inscriptionEdited.email = inscriptionEdited.email.toLowerCase())"
            v-model="inscriptionEdited.email"
            :rules="[rules.required, rules.email]"
        ></v-text-field>
        <v-text-field
            outlined
            label="Numero de celular"
            append-icon="mdi-cellphone"
            v-model="inscriptionEdited.celular"
            :rules="[rules.required, rules.phoneLength]"
        ></v-text-field>
        <!--        <v-text-field
                    outlined
                    label="Distrito de residencia"
                    append-icon="mdi-map-marker"
                    v-model="inscriptionEdited.ubicacion"
                    :rules="[rules.required]"
                ></v-text-field>-->
        <v-autocomplete
            :items="departments"
            item-value="id"
            item-text="name"
            outlined
            v-model="inscriptionEdited.region"
            label="Region"
            :rules="[rules.required]"
        />
        <v-autocomplete
            :items="secciones"
            item-text="nombre"
            item-value="id"
            outlined
            :rules="[rules.required]"
            label="Seccion"
            append-icon="mdi-office-building"
            v-model="inscriptionEdited.seccion"
            return-object
        >
        </v-autocomplete>
        <v-autocomplete
            :items="instituciones"
            item-text="nombre"
            item-value="id"
            :rules="[rules.required]"
            outlined
            label="Universidad de estudio"
            append-icon="mdi-office-building"
            v-model="inscriptionEdited.institucion"
            return-object
        >
        </v-autocomplete>
        <!--        <v-checkbox-->
        <!--            class="mt-0"-->
        <!--            v-model="inscriptionEdited.autoriza_foto"-->
        <!--            label="Autorizacion de imagen"-->
        <!--        ></v-checkbox>-->
        <v-card outlined class="card-vouchers">
          <v-card-subtitle>Vouchers</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col
                v-for="(voucher, index) in inscriptionEdited.vouchers"
                :key="voucher.id"
                cols="12"
                sm="6"
              >
                <v-badge color="error" overlap style="width: 100%;">
                  <template v-slot:badge>
                    <v-icon @click="removeVoucher(index)">mdi-minus</v-icon>
                  </template>
                  <!-- <v-img class="voucher-image" :src="voucher.imagen"></v-img> -->
                  <BaseImageViewer
                      v-if="voucher.imagen"
                      :imageSrc="voucher.imagen"
                  />
                </v-badge>
              </v-col>
              <v-col cols="12" sm="6" v-for="(files, index) in newFiles" :key="index">
                <v-badge color="error" overlap style="width: 100%;">
                  <template v-slot:badge>
                    <v-icon @click="removeNewVoucher(index)">mdi-minus</v-icon>
                  </template>
                  <v-img class="voucher-image" :src="files.url"></v-img>
                </v-badge>
              </v-col>
              <v-col cols="12" sm="6" class="d-flex align-center">
                <label
                    for="file-input-voucher"
                    class="d-flex justify-center align-center upload-button"
                >
                  <v-icon>mdi-plus</v-icon>
                </label>
                <input
                    type="file"
                    id="file-input-voucher"
                    multiple
                    accept="image/*"
                    @change="onFileChangeVoucher"
                    ref="fileInputVoucher"
                    class="d-none"
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-text-field
            outlined
            label="DNI"
            class="mt-5"
            append-icon="mdi-badge-account-horizontal"
            v-model="inscriptionEdited.documento"
            :rules="[rules.required, rules.dniCarnet]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card outlined class="card-vouchers mb-5">
          <v-card-subtitle>Fotos de DNI</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" v-if="photoImgSrc">
                <v-badge color="error" overlap style="width: 100%;">
                  <template v-slot:badge>
                    <v-icon @click="removeDni()">mdi-minus</v-icon>
                  </template>
                  <!-- <v-img class="voucher-image" :src="voucher.imagen"></v-img> -->
                  <BaseImageViewer
                      :imageSrc="photoImgSrc"
                  />
                </v-badge>
              </v-col>
              <v-col cols="12" sm="6" class="d-flex align-center" v-if="dniPhotos.length < 1">
                <label
                    for="file-input-dni"
                    class="d-flex justify-center align-center upload-button"
                >
                  <v-icon>mdi-plus</v-icon>
                </label>
                <input
                    id="file-input-dni"
                    type="file"
                    multiple
                    accept="image/*"
                    @change="onFileChangeDni"
                    ref="fileInputDni"
                    class="d-none"
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!--        <v-select
                  :items="grados"
                  item-value="id"
                  item-text="nombre"
                  outlined
                  v-model="inscriptionEdited.grado"
                  label="Grado de formación"
                  :rules="[rules.required]"
                />-->
        <v-combobox
            v-model="inscriptionEdited.grados_formacion"
            :items="grados"
            item-text="nombre"
            item-value="id"
            chips
            clearable
            label="Grados de formacion"
            multiple
            outlined
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="removeGrado(item)"
            >
              <strong>{{ item.nombre }}</strong>&nbsp;
            </v-chip>
          </template>
        </v-combobox>
        <v-text-field
            outlined
            label="Número de carnet CMP"
            append-icon="mdi-badge-account"
            v-model="inscriptionEdited.carnet_cmp"
            autocomplete="off"
            :rules="[rules.maxLength(8)]"
        ></v-text-field>
        <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateFormatted"
                label="Fecha nacimiento"
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                outlined
                :rules="[rules.required]"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="inscriptionEdited.fecha_nacimiento"
              @input="dateMenu = false"
          ></v-date-picker>
        </v-menu>
        <v-combobox
            v-model="inscriptionEdited.canales_referencia"
            :items="canales"
            item-text="nombre"
            item-value="id"
            chips
            clearable
            label="Canales de referencia"
            multiple
            outlined
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="remove(item)"
            >
              <strong>{{ item.nombre }}</strong>&nbsp;
            </v-chip>
          </template>
        </v-combobox>
        <v-select
            :items="states"
            item-value="value"
            item-text="name"
            outlined
            v-model="inscriptionEdited.estado"
            label="Estado"
            :rules="[rules.required, rules.requiredSelect]"
        >
        </v-select>
        <v-text-field
            v-if="$route.name === 'NewInscription' ? inscriptionEdited.codigo : inscription.codigo "
            outlined
            label="Código Promocional"
            :value="inscription.codigo.codigo"
            readonly
        ></v-text-field>
        <v-text-field
            outlined
            label="Otro canal de referencia"
            v-model="inscriptionEdited.otro_canal_referencia"
        ></v-text-field>
        <v-textarea
            outlined
            label="Comentario"
            v-model="inscriptionEdited.comentario"
        ></v-textarea>
        <div class="full-width text-right">
          <v-btn color="error" v-if="inscriptionEdited && inscriptionEdited.id" @click="doDeleteInscription()"
                 class="ma-2" style="text-transform: none"
          >
            Eliminar
          </v-btn>
          <v-btn color="success" :disabled="!validForm" @click="openMatriculaModal" class="ma-2"
                 style="text-transform: none" v-if="$route.name!=='NewInscription'">
            Matricular
          </v-btn>
          <v-btn color="info" :disabled="!validForm" :loading="saveLoading" type="submit" class="ma-2"
                 style="text-transform: none">
            Guardar
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <TheMatriculaModal v-model="matriculaModal" :inscription="inscription" @close="matriculaModal=false"
                       :usamedicUser="usamedicUser"></TheMatriculaModal>
  </v-form>
</template>

<script>
import {required, email, requiredSelect, dniCarnet, equalToLength, maxLength, minLength, phoneLength} from '@/utils/rules'
import updateForm from '@/mixins/updateForm'
import BaseImageViewer from '@/components/commonComponents/BaseImageViewer'
import TheMatriculaModal from '@/components/dashboardComponents/TheMatriculaModal'
import axios from "axios";

export default {
  name: "TheInscriptionForm",
  components: {
    TheMatriculaModal,
    BaseImageViewer
  },
  mixins: [updateForm],
  props: {
    saveLoading: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    inscription: {
      type: Object,
      default: () => ({
        nombres: '',
        apellidos: '',
        autoriza_foto: false,
        canales_referencia: [],
        carnet_cmp: '',
        celular: '',
        comentario: '',
        email: '',
        estado: "RECIBIDO",
        fecha_nacimiento: '',
        institucion: null,
        otro_canal_referencia: '',
        seccion: null,
        region: null,
        // ubicacion: '',
        vouchers: [],
        foto_dni: [],
        grados_formacion: [],
        matricula: null
      }),
    },
    initial: {
      type: Object,
      default: () => {
      }
    },
    updateInscEdited: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      usamedicUser: {},
      matriculaModal: false,
      states: [
        {
          name: 'Recibido',
          value: 'RECIBIDO',
        },
        {
          name: 'Listo',
          value: 'LISTO',
        },
        {
          name: 'Verificado',
          value: 'VERIFICADO',
        },
        {
          name: 'No procede',
          value: 'NO_PROCEDE',
        },
      ],
      datePicker: false,
      dateMenu: false,
      rules: {
        required,
        maxLength,
        minLength,
        phoneLength,
        email,
        requiredSelect,
        dniCarnet,
        equalToLength
      },
      inscriptionEdited: {
        nombres: '',
        apellidos: '',
        autoriza_foto: false,
        canales_referencia: [],
        carnet_cmp: '',
        celular: '',
        comentario: '',
        email: '',
        estado: "RECIBIDO",
        fecha_nacimiento: '',
        institucion: null,
        otro_canal_referencia: '',
        seccion: null,
        region: null,
        // ubicacion: '',
        vouchers: [],
        foto_dni: [],
        grados_formacion: [],
        newFiles: [],
        deletedVouchers: [],
        matricula: null,
      },
      canales: null,
      secciones: null,
      instituciones: null,
      newFiles: [],
      newFilesDni: [],
      deletedVouchers: [],
      validForm: false,
      regions: [],
      grados: [],
      dniPhotos: [],
      deleted: false
    }
  },
  computed: {
    dateFormatted() {
      return this.formatDate(this.inscriptionEdited.fecha_nacimiento)
    },
    departments() {
      return this.regions.filter(item => item.country.id === 10 || item.name === 'México')
    },
    photoImgSrc() {
      if (typeof this.dniPhotos[0] === 'string') {
        return this.dniPhotos[0]
      } else {
        if (this.dniPhotos.length) {
          return this.preview(this.dniPhotos[0])
        } else {
          return null
        }
      }
    }
  },
  methods: {
    preview(img) {
      return URL.createObjectURL(img)
    },
    async openMatriculaModal() {
      // if any field of the form is changed
      const count = await this.testChanges()
      // console.log('contador ', count)
      if (count === 20) {
        const confirm = await this.$root.$confirm({
          title: '',
          message: `¿Estás seguro de matricular a ${this.inscription.nombres + ' ' + this.inscription.apellidos}? `,
          acceptText: 'Si',
          cancelText: 'No'
        })
        if (confirm) {
          this.matriculaModal = true
        }
      } else {
        await this.firstSaveInfo()
      }
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    async fetchInstituciones() {
      const institucionesResponse = await this.$http.get('api/instituciones/')
      this.instituciones = institucionesResponse.data
    },
    async fetchCanales() {
      const canalesResponse = await this.$http.get('api/matriculas/canales_referencia/')
      this.canales = canalesResponse.data
    },
    async fetchSecciones() {
      const seccionesResponse = await this.$http.get('api/matriculas/secciones/')
      this.secciones = seccionesResponse.data
    },
    async fetchCities() {
      const citiesResponse = await this.$http.get('api/ciudades/region/')
      this.regions = citiesResponse.data
    },
    async fetchGrados() {
      const gradosResponse = await this.$http.get('api/matriculas/grados_formacion/')
      this.grados = gradosResponse.data
    },
    remove(item) {
      this.inscriptionEdited.canales_referencia.splice(this.inscriptionEdited.canales_referencia.indexOf(item), 1)
      this.inscriptionEdited.canales_referencia = [...this.inscriptionEdited.canales_referencia]
    },
    removeGrado(item) {
      this.inscriptionEdited.grados_formacion.splice(this.inscriptionEdited.grados_formacion.indexOf(item), 1)
      this.inscriptionEdited.grados_formacion = [...this.inscriptionEdited.grados_formacion]
    },
    onFileChangeVoucher(e) {
      const file = e.target.files[0]
      this.newFiles.push({
        image: file,
        url: URL.createObjectURL(file)
      })
      this.$refs['fileInputVoucher'].value = ''
    },
    onFileChangeDni(e) {
      const file = e.target.files[0]
      this.dniPhotos.push(file)
    },
    removeVoucher(index) {
      this.deletedVouchers.push(this.inscriptionEdited.vouchers[index])
      this.inscriptionEdited.vouchers.splice(index, 1)
    },
    removeDni() {
      this.dniPhotos = []
    },
    removeNewVoucher(index) {
      this.newFiles.splice(index, 1)
    },
    async doSubmit() {
      if (this.$route.name === 'NewInscription') {
        if (this.$refs.form.validate()) {
          if (this.newFiles.length > 0) {
            this.inscriptionEdited.newFiles = this.newFiles
            this.inscriptionEdited.deletedVouchers = this.deletedVouchers
            if (this.dniPhotos.length < 1) {
              this.$root.$confirm({
                title: '¡Ups!',
                message: 'Debe ingresar al menos una foto de DNI.'
              })
            } else {
              this.inscriptionEdited.foto_dni = this.dniPhotos[0]
              await this.$emit('validated', this.inscriptionEdited)
              // await this.matchEmail(this.inscription.email)
            }
          } else {
            this.$root.$confirm({
              title: '¡Ups!',
              message: 'Debe ingresar al menos un voucher.'
            })
          }
        } else {
          this.$root.$confirm({
            title: 'Mensaje',
            message: 'Asegúrese de llenar correctamente todos los campos.'
          })
        }
      } else {
        if (this.$refs.form.validate()) {
          if (this.inscriptionEdited.vouchers.length > 0 || this.newFiles.length > 0) {
            this.inscriptionEdited.newFiles = this.newFiles
            this.inscriptionEdited.deletedVouchers = this.deletedVouchers
            this.inscriptionEdited.foto_dni = this.dniPhotos[0]
          } else {
            this.$root.$confirm({
              title: '¡Ups!',
              message: 'Debe ingresar al menos un voucher.'
            })
            return
          }
          if (!this.inscriptionEdited.foto_dni) {
            this.$root.$confirm({
              title: '¡Ups!',
              message: 'Debe ingresar al menos una foto de DNI.'
            })
            return
          }
          this.$emit('validated', this.inscriptionEdited)
          // this.matchEmail()
        } else {
          this.$root.$confirm({
            title: 'Mensaje',
            message: 'Asegúrese de llenar correctamente todos los campos.'
          })
        }
      }
    },
    async deleteInscription() {
      await this.$http.delete(`api/admin/matriculas/formularios/${this.inscription.id}/`)
    },
    async doDeleteInscription() {
      try {
        let message = 'Se eliminará de forma permanente esta inscripción.'
        if (this.inscription.matricula) {
          message = `Se eliminará también la matricula asociada a esta inscripción.<br><br>
            <ul>
                <li>${this.inscription.matricula.user_display_name} - ${this.inscription.matricula.seccion_name}</li>
            </ul>`
        }
        const confirm = await this.$root.$confirm({
          title: '¿Estás seguro?',
          message,
          acceptText: "Si",
          cancelText: "No"
        })
        if (confirm) {
          this.$emit('deleted')
          await this.deleteInscription()
          await this.$root.$confirm({
            title: 'Mensaje',
            message: 'Inscripción eliminada',
            acceptText: "Ok"
          })
          await this.$router.push({name: 'Inscriptions'})
        }
      } catch (e) {
        await this.$root.$confirm({
          title: '¡Ups!',
          message: 'Ha ocurrido un error inesperado. Inténtelo de nuevo en unos minutos.'
        })
      }
    },
    async matchEmail(emailToMatch) {
      try {
        const response = await axios.get(`api/admin/matriculas/users/verify_email/?email=${emailToMatch}`)
        if (response.status === 200) {
          this.usamedicUser = response.data
        }
        if (response.status === 204) {
          this.usamedicUser = {}
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  watch: {
    updateInscEdited() {
      this.inscriptionEdited = this.inscription
      this.newFiles = []
      this.deletedVouchers = []
    }
  },
  async mounted() {
    if (this.inscription.nombres && this.inscription.apellidos) {
      this.inscriptionEdited = this.inscription
      if (this.inscriptionEdited.foto_dni) {
        this.dniPhotos.push(this.inscriptionEdited.foto_dni)
      }
    }
    if (this.$route.name === 'UpdateInscription') {
      await this.matchEmail(this.inscription.email)
    }
  },
  async created() {
    await this.fetchCanales()
    await this.fetchSecciones()
    await this.fetchInstituciones()
    await this.fetchCities()
    await this.fetchGrados()
  }
}
</script>

<style scoped>

</style>
